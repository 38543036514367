<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
        <form @submit.prevent="submit">
            <b-field label="Name">
                <b-input :value="form.mahasiswa" custom-class="is-static" readonly />
            </b-field>
            <b-field label="NIM">
                <b-input :value="form.noid" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Email">
                <b-input :value="form.email" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Kontak">
                <b-input :value="form.phone" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Konsentrasi">
                <b-input :value="form.kbk" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Kelas">
                <b-input :value="form.kelas" custom-class="is-static" readonly />
            </b-field>
            <hr>
            <b-field label="Judul Proposal">
                <b-input :value="form.judul" custom-class="is-static" readonly />
            </b-field>

            <b-field label="Revisi Judul" horizontal>
              <b-input
                type="text"
                v-model="form.perbaikan_judul"
              />
            </b-field>
            <b-field label="Hasil Evaluasi" horizontal>
              <v-select v-model="form.status_id" label="name" :options="[ { id: 1, name: 'Pengajuan'}, { id: 2, name: 'Diterima'}, { id: 3, name: 'Ditolak'} ]" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field label="Catatan" horizontal>
              <b-input  type="textarea"  v-model="form.note"></b-input>
            </b-field>
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component style="display:none">
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false,
      kbk: [],
      jenjang: [],
      kelas: []
    }
  },
  computed: {
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Form Evaluasi Proposal'
      }

      return ['Dashboard', 'Pengajuan Proposal', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Form Evaluasi Proposal'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'proposal.create' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Edit Proposal'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Proposal'
      } else {
        return 'Edit Pengajuan Proposal'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
  },
  created () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        noid: null,
        name: null,
        email: null,
        phone: null
      }
    },
    getData () {
      if (this.$route.params.id) {
        const id = parseInt(this.$route.params.id)
        axios
          .get(`/dosen_proposals/${id}`)
          .then((r) => {
            console.log(r.data)
            if (r.data.status === 'ok') {
              this.form = r.data.data
              this.form.kbk_id = parseInt(this.form.kbk_id)
              this.isProfileExists = true
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.$router.push({ name: 'home_dosen' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form

      this.isLoading = true

      axios
        .post(`/dosen_proposals/${this.$route.params.id}`, data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>
